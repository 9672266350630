import React from "react";
import Posts from "../components/Posts";
import CategorySelect from "../components/CategorySelect";

const Home = () => {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <CategorySelect />
      <Posts />
    </div>
  );
};

export default Home;
