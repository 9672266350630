// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  width: 60px;
  height: 60px;
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-left-color: #7983ff;
  border-radius: 50%;
  animation: spin .75s linear infinite;
}
.spinner-btn {
  width: 24px;
  height: 24px;
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-left-color: #7983ff;
  border-radius: 50%;
  animation: spin .75s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,sCAAsC;EACtC,0BAA0B;EAC1B,kBAAkB;EAClB,oCAAoC;AACtC;AACA;EACE,WAAW;EACX,YAAY;EACZ,sCAAsC;EACtC,0BAA0B;EAC1B,kBAAkB;EAClB,oCAAoC;AACtC;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".spinner {\n  width: 60px;\n  height: 60px;\n  border: 0.2em solid rgba(0, 0, 0, 0.1);\n  border-left-color: #7983ff;\n  border-radius: 50%;\n  animation: spin .75s linear infinite;\n}\n.spinner-btn {\n  width: 24px;\n  height: 24px;\n  border: 0.2em solid rgba(0, 0, 0, 0.1);\n  border-left-color: #7983ff;\n  border-radius: 50%;\n  animation: spin .75s linear infinite;\n}\n\n@keyframes spin {\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
